@import 'global';

body {
    margin: 0;
    font-family: @font;
   -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.CodeMirror, .CodeMirror * {
    font-family: @font;
}